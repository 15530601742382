import React from 'react';
import { CommonBaseContent } from '../Layouts/CommonBackContent';
import dynamic from 'next/dynamic';
import styles from './index.module.less';
// import { useTranslation } from 'next-i18next';
// import TopContainer from './TopContainer';
// import FlowContent from './FlowContent';
// import SingleContent from './SingleConent';
// import AllInContent from './AllInConent';

const TopContainer = dynamic(() => import('./TopContainer'));
const FlowContent = dynamic(() => import('./FlowContent'));
const SingleContent = dynamic(() => import('./SingleContent'));
const AllInContent = dynamic(() => import('./AllInContent'));

const TopContent = dynamic(() => import('./TopContent'));
const ChooseContent = dynamic(() => import('./ChooseContent'));
const FAQContent = dynamic(() => import('../FAQContent'));
const MeetMyBabyContent = dynamic(() => import('./MeetMyBabyContent'));
const FeaturesContent = dynamic(() => import('./FeaturesContent'));
const MeetPlanbowlContent = dynamic(() => import('./MeetPlanbowlContent'));

const HairStyleContent = ({ data }: { data?: any }) => {
  // const { t } = useTranslation('common');

  return (
    <>
      <div className={styles.content}>
        <TopContent />
      </div>
      <TopContainer />
      <div className={styles.content}>
        <AllInContent />
        <SingleContent dataList={data?.dataList} />
      </div>
      <FlowContent />
      <CommonBaseContent />
      <FeaturesContent />
      <MeetPlanbowlContent />
      <ChooseContent />
      <FAQContent isFromHome={true} />
      <div className={styles.content}>
        <MeetMyBabyContent />
      </div>
    </>
  );
};

export default HairStyleContent;
